import React, { useEffect, useState } from "react"

import { goto } from "../_utils/Misc"
import Database from "../_utils/Database"
import Qr from "../_utils/Qr"
import { endGame } from "../_services/game.service"

import Layout from "../_components/Layout/Layout"
import GameQuiz from "../_components/GameQuiz"


const SuggestMenu = () => {
    const [count, setCount] = useState(3)
    const [start, setStart] = useState(false)
    const [game, setGame] = useState(false)
    const [mainImage, setMainImage] = useState(false)
    const [endResponseImg, setEndResponseImg] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [levelsNumber, setLevelsNumber] = useState(0)
    const [gamePayload, setGamePayload] = useState({
        title: '',
        levels: []
    })
    const [currentLevel, setCurrentLevel] = useState(0)
    const [endResponse, setEndResponse] = useState(null)
    const [error, setError] = useState(false)
    const [busy, setBusy] = useState(false)

    const [singleItem, setSingleItem] = useState({})
    const [choices, setChoices] = useState({})

    useEffect(() => {
        if (!!Qr.getGameParams()) {
            let gameInfo = Qr.getGameParams()

            setGamePayload(gameInfo)

            if (!!gameInfo && !!gameInfo.levels.length) {
                setLevelsNumber(gameInfo.levels.length - 1)
            }
        } else {
            goto("/")
        }
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [currentLevel, endResponse, start])

    const countDown = (modal) => {
        const timer = window.setInterval(() => {
            setCount(prevCount => {
                if (prevCount > 1) {
                    return prevCount - 1
                } else {
                    if (modal) {
                        if (Database.getUserToken()) {
                            goto("/qr-rewards-fetch/")
                        } else {
                            goto("/registration/")
                        }
                    } else {
                        setGame(true)
                    }

                    return window.clearInterval(timer)
                }
            })
        }, 1000)
    }

    const startGame = () => {
        setStart(true)
        countDown()
        setTimeout(() => setStart(false), 3000)
    }

    const hideGame = (url) => {
        goto(`/${url}`)
    }

    const goNext = () => {
        setCurrentLevel(0)
        setGame(false)
        setEndResponse(null)
        setCount(3)
        setChoices({})
        setSingleItem({})
        startGame()
    }

    const handleNextQuestion = (e, item) => {
        const level = item?.nextLevel
        setLoaded(false)

        if (level !== 'end') {
            setCurrentLevel(+level)
        } else {
            let params = {
                campaign_id: Qr.getGameParams()?.campaign_id,
                value: Array.isArray(choices[currentLevel]?.itemValue)
                    ? choices[currentLevel]?.itemValue.join(', ')
                    : choices[currentLevel]?.itemValue,
                consumer_id: null
            }

            Qr.storeChoices(Object.values(choices)?.map(item => item.itemValue).flat())

            setBusy(true)

            endGame(params).then(response => {
                if (!!response && response.title) {
                    setEndResponse(response)
                } else {
                    setError(true)
                }

                setBusy(false)
            }, () => {
                setError(true)
            })
        }
    }

    return (
        <div className={`taste-game ${!busy && !start ? 'start' : ''}`}>
            <Layout title="Taste Game">
                {!busy && !start && !game && (
                    <div className="start-content">
                        <div>
                            <div className="main-title">
                                {gamePayload?.title}
                            </div>

                            <div className="main-description game">
                                {gamePayload?.description}
                            </div>

                            <div className="game-image main">
                                {!mainImage && (
                                    <span className="loading">
                                        Loading...
                                    </span>
                                )}
                                <img
                                    style={mainImage ? {} : { display: 'none' }}
                                    onLoad={() => setMainImage(true)}
                                    src={gamePayload?.img_src}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="game-buttons">
                            <button
                                onClick={startGame}
                                className="btn btn-yellow"
                            >
                                Start
                            </button>
                        </div>
                    </div>
                )}

                {!busy && start && (
                    <div className="count-content">
                        {count}
                    </div>
                )}

                {!!busy && (
                    <div className="busy loading">
                        Loading...
                    </div>
                )}

                {!busy && game && !error && !endResponse && (
                    <>
                        <div className="main-title game">
                            {gamePayload?.levels[currentLevel]?.title}
                        </div>
                        <div className="game-level">
                            <GameQuiz
                                level={gamePayload?.levels[currentLevel]}
                                currentLevel={currentLevel}
                                loaded={loaded}
                                setLoaded={setLoaded}
                                singleItem={singleItem}
                                setSingleItem={setSingleItem}
                                choices={choices}
                                setChoices={setChoices}
                                handleNextQuestion={handleNextQuestion}
                            />
                        </div>
                    </>
                )}

                {!busy && !!endResponse && !!endResponse.choice && !error && (
                    <div className="start-content">
                        <div className="main-title">
                            {gamePayload?.completion_message || 'Congratulations you have won!'}
                        </div>

                        <div className="main-description game">
                            {endResponse.title}
                        </div>

                        <div className="game-level suggest">
                            <div className="game-image main suggest">
                                <span className="food-name">
                                    {endResponse.choice.name}
                                </span>

                                {!endResponseImg && (
                                    <span className="loading">
                                        Loading...
                                    </span>
                                )}

                                <div className="suggested-img">
                                    <div className="blurred-bg">
                                        <img src={endResponse.choice.image_src} alt="" />
                                    </div>

                                    <img
                                        style={endResponseImg ? {} : { display: 'none' }}
                                        onLoad={() => setEndResponseImg(true)}
                                        src={endResponse.choice.image_src}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="main-description game">
                            {endResponse.choice.description}
                        </div>

                        <div className="game-buttons suggest">
                            <button
                                onClick={() => hideGame('map-preview')}
                                className="btn btn-yellow"
                            >
                                Go to map
                            </button>

                            <button
                                onClick={goNext}
                                className="btn btn-yellow"
                            >
                                Find me another dish
                            </button>

                            <button
                                onClick={() => hideGame('home')}
                                className="btn btn-yellow"
                            >
                                Home
                            </button>
                        </div>
                    </div>
                )}

                {!busy && !!error && (
                    <div className="won-content">
                        <div className="main-title">
                            Sorry we are not able to provide a suggestion at this time
                        </div>

                        <div className="game-buttons">
                            <button
                                onClick={() => hideGame('home')}
                                className="btn btn-yellow"
                            >
                                Thank you
                            </button>
                        </div>
                    </div>
                )}
            </Layout>
        </div>
    )
}

export default SuggestMenu
