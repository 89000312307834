import * as React from "react"
import { useState, useEffect } from "react"
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import "./styles.scss"

const GameQuiz = (props) => {
    const { level, loaded, setLoaded,
        singleItem, setSingleItem, currentLevel,
        choices, setChoices, handleNextQuestion
    } = props

    const [sorted, setSorted] = useState([])
    const [multipleAnswers, setMultipleAnswers] = useState([])

    useEffect(() => {
        if (level?.type === 'sort') {
            setSorted(
                level?.choices?.map((item, index) => {
                    return { ...item, order: index }
                })
            )
        }
    }, [level])

    useEffect(() => {
        if (!!sorted?.length || !!multipleAnswers?.length) {
            setChoices(prev => ({
                ...prev,
                [currentLevel]: {
                    type: level?.type,
                    itemValue: !!sorted?.length
                        ? sorted?.map(item => item.value)
                        : multipleAnswers
                }
            }))
        }
    }, [sorted, multipleAnswers])

    const handleChange = (e, item) => {
        const { checked, value } = e.target;

        const tempChoices = choices;
        let tempValue = value;

        if (level?.type === 'multiple') {
            const currentQuestionValue = !!tempChoices[currentLevel]
                ? tempChoices[currentLevel]?.itemValue
                : []

            if (checked) {
                tempValue = [...currentQuestionValue, value]
            } else {
                tempValue = currentQuestionValue.filter(item => item !== value)
            }
            setMultipleAnswers(tempValue)
        } else {
            tempChoices[currentLevel] = {
                type: level?.type || 'single',
                itemValue: tempValue
            }

            setChoices(tempChoices)
        }

        if (!level?.type) {
            setSingleItem(item)
        }
    }

    const SortableItem = sortableElement(({ item }) =>
        <p
            className="answer"
            style={{
                backgroundColor: '#E8568B',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '37px',
                color: '#fff',
                fontSize: '16px',
                textTransform: 'uppercase',
                fontFamily: "Averta-Bold",
            }}
        >
            {item.name}
        </p>
    )

    const SortableContainer = sortableContainer(({ children }) => {
        return <div className="container">{children}</div>
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setSorted(prevState => {
            const newItems = [...prevState]

            if (oldIndex > newIndex) {
                for (let i = oldIndex - 1; i >= newIndex; i--) {
                    newItems[i].order++
                    newItems[oldIndex].order = newIndex
                }
            } else if (oldIndex < newIndex) {
                for (let i = oldIndex + 1; i <= newIndex; i++) {
                    newItems[i].order--
                    newItems[oldIndex].order = newIndex
                }
            }
            return newItems.sort((a, b) => a.order - b.order)
        })
    }

    return (
        <>
            {level?.type === 'slider' && (
                <div className="question-slider">
                    {!loaded && (
                        <span className="loading">
                            Loading...
                        </span>
                    )}
                    <img
                        style={loaded ? {} : { display: 'none' }}
                        onLoad={() => setLoaded(true)}
                        src={level?.img_src}
                        alt=""
                    />
                    <div className="slider-container">
                        <input
                            type="range"
                            className="slider"
                            step="1"
                            min="1"
                            max="10"
                            value={choices[currentLevel]?.itemValue}
                            onChange={handleChange}
                        />
                        <div className="slider-text">
                            <p>{level?.minText}</p>
                            <p>{level?.maxText}</p>
                        </div>
                    </div>
                </div>
            )}

            {level?.type === 'sort' && (
                <div className="question-sort d-flex flex-column">
                    <SortableContainer onSortEnd={onSortEnd}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div>
                                {Array.from({ length: sorted?.length }, (value, index) => index).map((number, numberIndex) =>
                                    <p key={numberIndex} className="number">{number + 1}</p>
                                )}
                            </div>
                            <div className="w-100">
                                {sorted?.map((item, index) => (
                                    <SortableItem key={index} index={index} item={item} />
                                ))}
                            </div>
                        </div>
                    </SortableContainer>
                </div>
            )}

            {level?.type === 'multiple' && (
                <>
                    {level?.choices?.map((item, index) => (
                        <div key={index} className="question-multiple">
                            <input
                                type="checkbox"
                                className="form-check"
                                value={item.value}
                                id={item.value}
                                checked={choices[currentLevel]?.itemValue?.includes[item.value]}
                                onChange={handleChange}
                            />
                            <label
                                className={`answer 
                                  ${choices[currentLevel]?.itemValue?.includes(item.value) ? 'active' : ''}
                                `}
                                htmlFor={item.value}
                            >
                                {item?.name}
                            </label>
                        </div>))}
                </>
            )}

            {!level?.type && (
                <>
                    {level?.choices?.map((item, index) => (
                        <div key={index}
                            className={`game-image ${singleItem?.value === item.value ? 'active' : ''}`}
                        >
                            <input
                                type="radio"
                                className="form-radio"
                                id={item.value}
                                value={item.value}
                                checked={singleItem?.value === item.value}
                                onChange={(e) => {
                                    handleChange(e, item)
                                    handleNextQuestion(e, item)
                                }}
                            />
                            <label htmlFor={item.value}>
                                <span className="food-name">
                                    {item.name}
                                </span>
                                {!loaded && (
                                    <span className="loading">
                                        Loading...
                                    </span>
                                )}
                                <img
                                    style={loaded ? {} : { display: 'none' }}
                                    // className={`${singleItem?.value === item.value ? 'active' : ''}`}
                                    onLoad={() => setLoaded(true)}
                                    src={item?.img_src}
                                    alt=""
                                />
                            </label>
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

export default GameQuiz;
